import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { productList } from "../configs/constantData";
import { useGlobalState } from "../context/GlobalStateProvider";

// Components
import CartDrawer from "../components/CartDrawer";

// API
import { addToCart } from "../ducks/services";
import { getUserProfile } from "../ducks/actions";

// Antd
import { Breadcrumb, message } from "antd";

// Icons
import { ShoppingOutlined } from "@ant-design/icons";

const breadcrumbItems = [
  { title: <Link to="/">Home</Link> },
  { title: <div>Products</div> },
];

const productsArr = [
  { ...productList.essentials },
  { ...productList.leanessentials },
];

function Products({ openCart, setOpenCart }) {
  const userData = useSelector((state) => state.global.userData);
  const dispatch = useDispatch();
  const { isLoggedIn, setLoading } = useGlobalState();
  const navigate = useNavigate();

  const handleAddToCart = async (paramId, event) => {
    event.stopPropagation();
    if (!isLoggedIn()) {
      message.error("Please login to continue.");
      navigate("/login");
      return;
    }
    setLoading(true);
    // const existingCartQty = userData?.cart?.cart_items?.find(
    //   (el) => el?.product?.id === Number(paramId)
    // )?.qty;

    // let addQtyAmt = 0;
    // if (existingCartQty) {
    //   addQtyAmt = existingCartQty + 1;
    // } else {
    //   addQtyAmt += 1;
    // }

    const payload = {
      product_id: paramId,
      quantity: 1,
      to_add: "1",
    };

    await addToCart(payload)
      .then((res) => {
        // props.setLoading(false);
        if (res.data.code === 200) {
          dispatch(getUserProfile());
          setOpenCart(true);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        // props.setLoading(false);
        message.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseCart = () => {
    setOpenCart(false);
  };

  return (
    <div className="productListPage">
      <div className="container">
        <div className="breadcrumbContainer m-y-30">
          <Breadcrumb separator=">" items={breadcrumbItems} />
        </div>

        <div className="productListContainer m-b-50">
          {productsArr.map((el, i) => (
            <div className="flex flex-col gap-20" key={`product-list-${i}`}>
              <div className="productImgContainer p-relative">
                <Link to={`/mct/${el.path}`}>
                  <img src={el.productImages[0]} alt={el.nameString} />
                </Link>
                <div
                  className="addToCartBtn"
                  onClick={(e) => handleAddToCart(el.id, e)}
                >
                  <ShoppingOutlined className="fs-20" />
                </div>
              </div>

              <Link className="info" to={`/mct/${el.path}`}>
                <div className="productName m-b-20">{el.productName}</div>
                <div className="price">RM{el.price}</div>
              </Link>
            </div>
          ))}
        </div>
      </div>

      <CartDrawer onOpenCart={openCart} onHandleCloseCart={handleCloseCart} />
    </div>
  );
}

export default Products;
