import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGlobalState } from "../context/GlobalStateProvider";
import { Link, useSearchParams, useLocation } from "react-router-dom";

import { getSalesOrder } from "../ducks/actions";

// Antd
import { Table, Tag, Spin, Row, Col } from "antd";

function OrderDetails({ failed }) {
  const { setLoading } = useGlobalState();
  const [searchParams] = useSearchParams();
  const paymentParams = useSelector(
    (state) => state.global.salesOrderMeta?.payment_request
  );
  const orderHistoryList = useSelector((state) => state.global.salesOrder);

  const dispatch = useDispatch();

  useEffect(() => {
    const payload = {
      token: searchParams.get("token"),
      failed: failed,
    };

    dispatch(getSalesOrder(setLoading, payload));
  }, [searchParams]);

  const tableHeader = [
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "Total Price",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  const tableData = [
    {
      key: "order-details",
      product: orderHistoryList?.sales_order_items?.map((el, i) => (
        <div>
          <div className="m-b-10">
            <Tag
              color={
                orderHistoryList?.status === "Pending" ? "#faad14" : "#f5222d"
              }
            >
              {orderHistoryList?.status}
            </Tag>
            <div>Date: {orderHistoryList?.datetime ?? "-"}</div>
            <div>Reference: {orderHistoryList?.reference ?? "-"}</div>
          </div>
          <div
            className={`flex gap-10 a-i-center ${i === 0 ? "m-b-20" : ""}`}
            key={`salesOrderDataDetails-${i}`}
          >
            <img
              width={100}
              src={el?.product?.featured_graphic_url}
              alt={el?.product?.name}
            />
            <div className="flex flex-col">
              <div>Name: {el?.product?.name ?? "-"}</div>
              <div>Desc: {el?.product?.description ?? "-"}</div>
              <div>Quantity: {el?.quantity ?? "-"}</div>
              <div>Price: RM{el?.amount ?? "-"}</div>
            </div>
          </div>
        </div>
      )),
      amount: (
        <div>
          <div>
            Amount: RM
            {orderHistoryList?.sales_order_items
              ?.reduce((acc, item) => acc + Number(item?.amount), 0)
              ?.toFixed(2)}
          </div>
          Shipping Fees: RM{orderHistoryList?.shipping_fees}
          <br />
          Total Amount: RM{orderHistoryList?.amount}
        </div>
      ),
    },
  ];

  return (
    <div
      className="orderDetailsPage flex j-c-center a-i-center m-y-30"
      style={{ minHeight: "50vh" }}
    >
      <div className="container">
        <div className="text-center">
          <div className="big-title text-uppercase m-b-20">
            Payment {failed === "1" ? "Failed" : "Success"}
          </div>
          <Row justify={"center"} className="m-b-20">
            <Col span={12}>
              <Table
                dataSource={tableData}
                columns={tableHeader}
                pagination={false}
              />
            </Col>
          </Row>

          {/* Payment form */}
          <Row justify={"center"}>
            <Col span={12} className="text-right">
              {failed === "1" && (
                <form
                  method="POST"
                  name="ePayment"
                  action="https://payment.ipay88.com.my/ePayment/entry.asp"
                >
                  <input
                    type="hidden"
                    name="MerchantCode"
                    value={paymentParams?.MerchantCode ?? ""}
                  />
                  <input
                    type="hidden"
                    name="PaymentId"
                    value={paymentParams?.PaymentId ?? ""}
                  />
                  <input
                    type="hidden"
                    name="RefNo"
                    value={paymentParams?.RefNo ?? ""}
                  />
                  <input
                    type="hidden"
                    name="Amount"
                    value={paymentParams?.Amount ?? ""}
                  />
                  <input
                    type="hidden"
                    name="Currency"
                    value={paymentParams?.Currency ?? ""}
                  />
                  <input
                    type="hidden"
                    name="ProdDesc"
                    value={paymentParams?.ProdDesc ?? ""}
                  />
                  <input
                    type="hidden"
                    name="UserName"
                    value={paymentParams?.UserName ?? ""}
                  />
                  <input
                    type="hidden"
                    name="UserEmail"
                    value={paymentParams?.UserEmail ?? ""}
                  />
                  <input
                    type="hidden"
                    name="UserContact"
                    value={paymentParams?.UserContact ?? ""}
                  />
                  <input
                    type="hidden"
                    name="Remark"
                    value={paymentParams?.Remark ?? ""}
                  />
                  <input
                    type="hidden"
                    name="Lang"
                    value={paymentParams?.Lang ?? ""}
                  />
                  <input
                    type="hidden"
                    name="SignatureType"
                    value={paymentParams?.SignatureType ?? ""}
                  />
                  <input
                    type="hidden"
                    name="Signature"
                    value={paymentParams?.Signature ?? ""}
                  />
                  <input
                    type="hidden"
                    name="ResponseURL"
                    value={paymentParams?.ResponseURL ?? ""}
                  />
                  <input
                    type="hidden"
                    name="BackendURL"
                    value={paymentParams?.BackendURL ?? ""}
                  />
                  <button
                    name="button"
                    className="btn btn-primary btn-large fs-20 b-30 px-5"
                    type="submit"
                    onClick={() => setLoading(true)}
                  >
                    Pay Now
                  </button>
                </form>
              )}

              {failed === "0" && (
                <Link to="/" className="btn btn-primary btn-large fs-20">
                  Back to Home
                </Link>
              )}
            </Col>
          </Row>

          {/* {failed === "0" && 
<Table dataSource={tableData} columns={tableHeader} pagination={false} />
          } */}
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
