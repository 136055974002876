import axios from "Services/axiosInterceptor";
import * as action_types from "./constants";
import { apiresource, apiMethod, externalBaseUrl } from "../configs/constants";

export const onClear = () => {
  return { type: action_types.DESTROY_SESSION };
};

export const getUserProfile = () => {
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/users/get`);
    // load && load(false);
    dispatch({
      type: action_types.USER_PROFILE,
      data: data,
    });
  };
};

export const getReviewsList = (payload) => {
  // load && load(true);
  // :product_id, :with_media (“1”/ “0"), s: “created_at DESC/ASC”
  const testpayload = {
    product_id: 7631,
    with_media: "1",
    created_at: "DESC",
  };

  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/reviews/list`, {
      ...testpayload,
    });
    // load && load(false);
    dispatch({
      type: action_types.REVIEWS_LIST,
      data: data,
    });
  };
};

export const getOption = () => {
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/users/option`);
    // load && load(false);
    dispatch({
      type: action_types.OPTION_LIST,
      data: data,
    });
  };
};

export const getOrderHistory = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/users/order-history`);
    load && load(false);
    dispatch({
      type: action_types.ORDER_HISTORY,
      data: data,
    });
  };
};

export const getUserAddresses = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/users/address-list`);
    load && load(false);
    dispatch({
      type: action_types.USER_ADDRESSES,
      data: data,
    });
  };
};

export const getSalesOrder = (load, payload) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/sales-orders/get`, { ...payload });
    load && load(false);
    dispatch({
      type: action_types.SALES_ORDER,
      data: data,
      meta: meta,
    });
  };
};
