import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

// Context
import { useGlobalState } from "../../context/GlobalStateProvider";

// APIs
import { removeFromCart, addToCart } from "../../ducks/services";
import { getUserProfile } from "../../ducks/actions";

// Antd
import { Drawer, message, Spin } from "antd";

// Images
// import essentialBoxImg from "../../assets/images/box_MCT Essentials_01.png";
// import leanEssentialBoxImg from "../../assets/images/box_MCT LeanEssentials_02.png";

// Icons
import { DeleteOutlined } from "@ant-design/icons";
import QtyEditor from "../common/QtyEditor";

function CartDrawer({ onOpenCart, onHandleCloseCart }) {
  const { isMobile, loading, setLoading } = useGlobalState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const reloadApi = () => {
    dispatch(getUserProfile());
  };

  const freeShippingAmt = 200;

  const userData = useSelector((state) => state.global.userData);

  const cartQty = userData?.cart?.cart_items?.reduce(
    (acc, cur) => acc + cur.qty,
    0
  );

  const cartData = userData?.cart;

  const freeShippingProgressWidth =
    cartData?.amount >= freeShippingAmt
      ? "100"
      : `${(cartData?.amount / freeShippingAmt) * 100}`;

  const navigateToProducts = () => {
    onHandleCloseCart();
    navigate("/products");
  };

  return (
    <Drawer
      className="cartDrawer custom-drawer"
      title={`Cart ${cartQty > 0 ? `(${cartQty})` : ""}`}
      onClose={onHandleCloseCart}
      open={onOpenCart}
      width={isMobile ? "100%" : "480px"}
      footer={
        cartQty > 0 ? (
          <div className="cartFooter">
            <div className="flex j-c-sb text-uppercase font-bold fs-20 p-t-12 p-b-20">
              <div>Total</div>
              <div className="text-red">RM{cartData?.amount}</div>
            </div>

            {/* <div className="grid grid-2-col gap-10 m-b-10"> */}
            {/* <button className="btn btn-primary btn-large">View Cart</button> */}
            <Link
              to="/checkout"
              className="btn btn-primary btn-large full-width m-b-15"
            >
              Check Out
            </Link>
            {/* </div> */}

            <div className="text-center m-b-20">
              Taxes and shipping calculated at checkout
            </div>
          </div>
        ) : (
          false
        )
      }
    >
      {/* Empty Cart */}
      {!cartQty && (
        <div className="cartContainer emptyCartContainer">
          <div className="cartContent text-center">
            <div className="fs-20 font-bold m-b-30">Your Cart is Empty</div>
            <div
              className="btn btn-primary btn-large fs-18"
              onClick={navigateToProducts}
            >
              Continue Shopping
            </div>
          </div>
        </div>
      )}

      {cartQty > 0 && (
        <div className="cartContainer hasCartContainer">
          <div className="cartContent">
            {/* <div className="freeShippingContainer flex p-b-24 a-i-end gap-10">
              <div className="flex flex-col full-width gap-5">
                <div className="paragraph">
                  {cartData?.amount >= freeShippingAmt &&
                    "Your order qualifies for free shipping"}
                  {cartData?.amount < freeShippingAmt && (
                    <span className="fs-12">
                      You're{" "}
                      <span className="fw-550">
                        RM{(freeShippingAmt - cartData?.amount).toFixed(2)}
                      </span>{" "}
                      away from free shipping
                    </span>
                  )}
                </div>
                <div className="progressBarContainer">
                  <div className="progressBarInactive"></div>
                  <div
                    className="progressBarActive"
                    style={{ width: `${freeShippingProgressWidth}%` }}
                  ></div>
                </div>
              </div>
              <div className="font-bold">RM{freeShippingAmt.toFixed(2)}</div>
            </div> */}

            <div className="cartItemsContainer">
              {cartData?.cart_items?.map((el, i) => (
                <CartItem
                  item={el}
                  reloadApi={reloadApi}
                  addToCart={addToCart}
                  key={`cart-item-${i}`}
                  loading={loading}
                  setLoading={setLoading}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </Drawer>
  );
}

const CartItem = ({ item, reloadApi, addToCart, loading, setLoading }) => {
  const cartItemId = item?.id;
  const productData = item?.product;
  const [cartItemQty, setCartItemQty] = useState(item.qty);
  const dispatch = useDispatch();

  useEffect(() => {
    setCartItemQty(item.qty);
  }, [item.qty]);

  const handleDeleteCart = async (id) => {
    setLoading(true);

    const payload = {
      cart_item_id: id,
    };

    await removeFromCart(payload)
      .then((res) => {
        // props.setLoading(false);
        if (res.data.code === 200) {
          reloadApi();
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        // props.setLoading(false);
        message.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleModifyCart = async (val) => {
    setLoading(true);

    const payload = {
      product_id: productData?.id,
      quantity: val,
    };

    await addToCart(payload)
      .then((res) => {
        // props.setLoading(false);
        if (res.data.code === 200) {
          dispatch(getUserProfile());
          // setOpenCart(true);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        message.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // const controller = new AbortController();
    // const signal = controller.signal;

    if (cartItemQty === 0) handleDeleteCart(cartItemId);

    if (
      cartItemQty !== 0 &&
      (cartItemQty < item?.qty || cartItemQty > item?.qty)
    ) {
      handleModifyCart(cartItemQty);
    }
  }, [cartItemQty]);

  const minusQty = () => {
    if (cartItemQty === 1) return;
    setCartItemQty((quantity) => quantity - 1);
  };

  const changeQty = (val) => {
    if (val.length === 1 && val === "0") {
      handleDeleteCart(cartItemId);
    } else {
      setCartItemQty(val);
    }
  };

  const addQty = () => {
    setCartItemQty((quantity) => quantity + 1);
  };

  return (
    <div className="cartItem flex j-c-sb m-t-20">
      <div className="productDetails flex gap-20 a-i-start">
        <img
          className="productImg"
          src={productData?.featured_graphic_url}
          alt={productData?.name}
        />

        <div className="productInfo">
          <div className="productDesc m-b-30">
            <div className="title font-bold m-b-10 text-uppercase">
              {productData?.name}
            </div>
            <div className="info">{productData?.description}</div>
          </div>

          <div className="productCta flex gap-10">
            <QtyEditor
              qty={cartItemQty}
              minusQty={minusQty}
              addQty={addQty}
              changeQty={changeQty}
              min={0}
            />
            <div
              className="deleteBtn text-red fs-18"
              onClick={() => handleDeleteCart(item?.id)}
            >
              <DeleteOutlined />
            </div>
          </div>
        </div>
      </div>

      <div className="totalPrice font-bold text-red">RM{item?.amount}</div>

      <Spin size="large" spinning={loading} fullscreen={true} />
    </div>
  );
};

export default CartDrawer;
