import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGlobalState } from "../context/GlobalStateProvider";

// Constant Data
import { isPro } from "../configs/constantData";

// Components
import CustomInput from "../components/common/CustomInput";

// API
import { submitContactForm } from "../ducks/services";
import { getOption } from "../ducks/actions";

// Antd
import { Form, Row, Col, Input, Select, message } from "antd";

// Scoped CSS
import styles from "./Contact.module.scss";

// Icons
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { WhatsApp } from "@mui/icons-material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

// Images
import bannerImg from "../assets/images/contact_banner.png";
import malaysiaImg from "../assets/images/msia-icon.png";

const locateUsData = [
  {
    icon: (
      <WhatsApp
        style={{ width: "46px", height: "46px", color: "var(--primary-color)" }}
      />
    ),
    title: "Phone Number",
    body: "+6012-5994314",
  },
  {
    icon: (
      <LocationOnOutlinedIcon
        style={{ width: "46px", height: "46px", color: "var(--primary-color)" }}
      />
    ),
    title: "Monolith Nutrition Sdn Bhd",
    subtitle: "200401015971 (654474-V)",
    body: `No.F-3-G, 32 Avenue,
    Pusat Perniagaan Bukit Serdang, Jalan BS 14/3,
    Taman Bukit Serdang,
    43300 Seri Kembangan, Selangor`,
  },
  {
    icon: (
      <EmailOutlinedIcon
        style={{ width: "46px", height: "46px", color: "var(--primary-color)" }}
      />
    ),
    title: "Email",
    body: "foundation@monolithnutrition.com",
  },
];

// for contact_type = “contact”
// name
// mobile
// country
// comments

function Contact() {
  const { setLoading } = useGlobalState();
  const dispatch = useDispatch();
  const optionList = useSelector((state) => state.global.optionList);
  const [form] = Form.useForm();

  const countryDropdown = optionList?.countries?.map((item) => ({
    label: item?.name,
    value: item?.name,
  }));

  useEffect(() => {
    dispatch(getOption());
  }, []);

  const formFields = [
    {
      label: "Name",
      name: "name",
      type: "text",
      required: true,
      reqMsg: "Please insert name!",
      placeholder: "Please insert name",
    },
    {
      label: "Contact Number",
      name: "mobile",
      type: "text",
      required: true,
      reqMsg: "Please insert contact number!",
      placeholder: "Please insert contact number",
    },
    {
      label: "Email",
      name: "email",
      type: "email",
      required: true,
      reqMsg: "Please insert email!",
      placeholder: "Please insert email",
    },
    {
      label: "Country",
      name: "country",
      type: "select",
      required: true,
      reqMsg: "Please select country!",
      options: countryDropdown,
      placeholder: "Country",
    },
    // {
    //   // label: "Country/Region",
    //   name: "state",
    //   type: "select",
    //   required: true,
    //   reqMsg: "Please select State/territory!",
    //   options: statesDropdown,
    //   placeholder: "State/territory",
    // },
    {
      label: "Comments",
      name: "comments",
      type: "textarea",
      required: true,
      reqMsg: "Please select comments!",
      placeholder: "Please insert comments",
    },
  ];

  const onFinish = async (val) => {
    setLoading(true);

    const payload = { ...val, contact_type: "contact", is_pro: isPro };

    await submitContactForm(payload)
      .then((res) => {
        // props.setLoading(false);
        if (res.data.code === 200) {
          message.success("Your feedback is submitted!");
          form.resetFields();
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        // props.setLoading(false);
        message.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.contactPage}>
      <div className={`${styles.bannerContainer} p-relative`}>
        <img className={styles.banner} src={bannerImg} alt="banner" />
        <div className={`${styles.bannerText} p-absolute fw-300`}>
          A journey of a thousand miles
          <br />
          begins with a single step
        </div>
      </div>
      <div className="container">
        <div className="big-title text-center text-uppercase m-y-50">
          Get In Touch!
        </div>

        <div className="paragraph text-center m-b-50">
          There’s never been a better time and reason to take charge, get
          involved, and tap into the opportunity of becoming a Monolith
          Nutrition Lifestyle Affiliate! Our quality products are some of the
          best tasting and effective foundational nutrition supplements that
          resonate and relate to everyday people who prioritise a holistic
          lifestyle of health and wellness. You are about to be rewarded in the
          business of improving other people’s health and lives through quality,
          affordable, honest, and enduring products.
        </div>

        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.contactForm} m-b-100`}
        >
          {formFields.map((el, i) => (
            <Fragment key={`contact-form-${i}`}>
              <CustomInput item={el} />
            </Fragment>
          ))}
          {/* <Row gutter={[5, 10]}>
            <Col span={24}>
              <Input placeholder="Name" size="large" />
            </Col>
            <Col span={6}>
              <Select
                defaultValue="60"
                options={[
                  {
                    value: "60",
                    label: (
                      <div className="flex gap-5 a-i-center">
                        <img width={24} src={malaysiaImg} alt="malaysia" />
                        60
                      </div>
                    ),
                  },
                ]}
                size="large"
                className="full-width"
              />
            </Col>
            <Col span={18}>
              <Input placeholder="Phone" size="large" />
            </Col>
            <Col span={24}>
              <div>Country</div>
              <Select
                className="full-width"
                defaultValue="Malaysia"
                options={[{ value: "Malaysia", label: "Malaysia" }]}
                size="large"
              />
            </Col>
            <Col span={24}>
              <button
                className="btn btn-primary btn-large full-width"
                type="submit"
              >
                Submit
              </button>
            </Col>
          </Row> */}

          <button
            className="btn btn-primary btn-large full-width"
            type="submit"
          >
            Submit
          </button>
        </Form>

        <div className={`${styles.locateUs} m-b-50`}>
          <div className="big-title text-center text-uppercase m-y-50">
            Locate Us
          </div>
          <div className={`${styles.gridContent} text-center`}>
            {locateUsData.map((el, i) => (
              <div className={styles.gridItem} key={`locateUs-grid-${i}`}>
                {el.icon}
                <div className="font-bold fs-20 text-uppercase">{el.title}</div>
                {el.subtitle && <div className="font-bold">{el.subtitle}</div>}
                <div className={`paragraph ${styles.contentBody}`}>
                  {el.body.split("\n").map((item, index) => (
                    <div
                      className="paragraph"
                      key={`locateUs-${el.title}-${index}`}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
