import axios from "axios";
import { message } from "antd";
import { externalBaseUrl } from "../configs/constants";
import store from "../redux/store";

const authInterceptors = axios.create({
  baseURL: externalBaseUrl,
});

authInterceptors.interceptors.request.use(
  (config) => {
    let token = undefined;
    let byebugToken = undefined;
    if (localStorage.getItem("token")) {
      token = JSON.parse(localStorage.getItem("token")).access_token;
      byebugToken = JSON.parse(
        localStorage.getItem("token")
      ).byebug_access_token;
    }

    if (token) {
      config.headers["access_token"] = `${token}`;
    }
    if (byebugToken) {
      config.headers["Authorization"] = `Bearer ${byebugToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

authInterceptors.interceptors.response.use(
  (response) => {
    // if ([406].includes(response.data.code)) {
    //   window.location.assign('/error');
    //   return Promise.reject(response);
    // } else
    if (response?.data?.meta?.access_token) {
      let existingToken = JSON.parse(localStorage.getItem("token"));
      if (existingToken) {
        existingToken.access_token = response.data.meta.access_token;
        localStorage.setItem("token", JSON.stringify(existingToken));
      }
    }

    if (response?.data?.meta?.stats) {
      store.dispatch(setStats(response?.data?.meta?.stats));
    }
    return response;
  },
  function (error) {
    if (
      [401, 1001, 1002].includes(error.response.data.code) ||
      error.response.data.message === "Unauthorized"
    ) {
      localStorage.clear();
      setTimeout(() => {
        if (error.response?.data?.message) message.error(error.response.data.message);
        setTimeout(() => {
          window.location.assign("/");
        }, 500);
      }, 300);
    }
    // else if ([404].includes(error.response.status)) {
    //   window.location.assign('/404')
    // }
    return Promise.reject(error.response);
  }
);
export default authInterceptors;
